import { parsePhoneNumber } from 'awesome-phonenumber'
import { z } from 'zod'
import _ from 'lodash'

const username = z.string({
                        required_error: 'Username is required',
                        invalid_type_error: 'Username must be a string',
                    })
                    .min(8, { message: 'Username must be 8 or more characters long' })
                    .max(64, { message: 'Username must be 64 or less characters long' })
                    .regex(/^[a-zA-Z]+/, { message: 'Username must start with a letter' })
                    .regex(/^[0-9a-zA-Z\-_\+\.]+$/, { message: 'Username contains illegal characters' })
                    .trim()


const email = z.string({
                    required_error: 'Email is required',
                    invalid_type_error: 'Email must be a string',
                })
                .nonempty({ message: 'Email cannot be empty' })
                .email({ message: 'Invalid email address' })
                        

const uuid = z.string({
                    required_error: 'UUID is required',
                    invalid_type_error: 'UUID must be a string',
                })
                .regex(/^[0-9a-fA-F]{24}$/, { message: 'Wrong UUID' })


const ipv4Netmask = z.string({
                            required_error: 'IP with netmask is required',
                            invalid_type_error: 'IP with netmask must be a string',
                        })
                        .regex(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/, { message: 'Invalid IPv4 address or netmask' });

const ipv6Netmask = z.string({
                            required_error: 'IP with netmask is required',
                            invalid_type_error: 'IP with netmask must be a string',
                        })
                        .regex(/^([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4})$/, { message: 'Invalid IPv6 address' }); // Füge hier die vollständige Regex für IPv6 hinzu

const ipNetmask = z.union([ipv4Netmask, ipv6Netmask]);

// const mySchema = z.object({
//   ipNetmask: ipNetmask.nullish().optional(),
//   // ... andere Felder
// });
// const ipNetmask = z.string({
//                         required_error: 'IP with netmask is required',
//                         invalid_type_error: 'IP with netmask must be a string',
//                     })
//                     .regex(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/, { message: 'Wrong IP or netmask' })
//                     // .regex( ipOptionalSubnetRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/, { message: 'Wrong IP or netmask' })


const token = (len) => z.string({
                            required_error: 'TOKEN is required',
                            invalid_type_error: 'TOKEN must be a string',
                        })
                        .regex(new RegExp(`^[0-9a-fA-F]{${len}}$`), { message: 'Wrong TOKEN' })


const phone = z.string({
                    required_error: 'Phone number is required',
                    invalid_type_error: 'Phone number must be a string',
                })
                .regex(/^[0-9+]+$/, { message: 'Phone number contains illegal characters' })
                .refine((value) => {
                        return parsePhoneNumber(value).valid
                    }, {
                        message: 'Wrong phone number format',
                });


const credential = (userCredential, requiredCredential) => {
    // console.log('credential ... userCredential, requiredCredential', userCredential, requiredCredential)

    if (userCredential?.[requiredCredential] !== true && _.get(userCredential, requiredCredential) !== true) {
        // console.log('userCredential?.[requiredCredential] !== true')
        return {
            error: {
                statusCode: 403, 
                message: `You don\'t have the necessary permission: ${_.camelCase(requiredCredential)}`,
            }
        }
    }

    return { success: true}
}

const credentials = async (userCredentials, requiredCredentials) => {
    // console.log('credentials ... userCredentials, requiredCredentials', userCredentials, requiredCredentials)
    if (Array.isArray(requiredCredentials)) {
        // Wenn es sich um ein Array handelt, wende $and Logik an
        return requiredCredentials.reduce(async (acc, cred) => {

            if (acc.error) return acc;

            const result = await credentials(userCredentials, cred);
            return result.error ? result : acc;
        }, { success: true, abc: 1 });
    } else if (typeof requiredCredentials === 'object' && requiredCredentials !== null) {

        if ('$or' in requiredCredentials) {
            let result = { success: false, abc: 2 }

            // $or: Mindestens eine der Bedingungen muss wahr sein
            for (let cred of requiredCredentials.$or) {
                const res = await credentials(userCredentials, cred);

                if (!result.success) {
                    result = res; // Gib das erfolgreiche Ergebnis zurück
                }
            }

            return result; // Keine Bedingung erfüllt
        } else if ('$and' in requiredCredentials) {

            // $and: Alle Bedingungen müssen wahr sein
            for (let cred of requiredCredentials.$and) {
                const res = await credentials(userCredentials, cred);

                if (!res.success) {
                    return res; // Gib den Fehler zurück
                }
            }

            return { success: true, abc: 3 }; // Alle Bedingungen erfüllt
        } else {
            // Einzelne Berechtigung überprüfen
            return await credential(userCredentials, Object.keys(requiredCredentials)[0]);
        }
    } else if (typeof requiredCredentials === 'string') {
        // Einzelne Berechtigung überprüfen
        return await credential(userCredentials, requiredCredentials);
    } else {
        return { success: false, abc: 4 };
    }
}

export default {
    z,
    username,
    email,
    uuid,
    ipNetmask,
    token,
    phone,
    credential,
    credentials,
}


/* TEST RUNNER FUER VALIDIERUNGEN VIA OW 
export default {
    auth,
    nonEmptyString,
    language,
    country,
    region,
    numberInteger,
    credentials,
    credential,
            uuid,
            token,
    tablename,
            username,
            email,
    it,
}*/